var BOOKTABLE_REDUX_NAME = "bookTable";

/**
 *
 * @param {*} state
 * @returns {{
 *  name:string,
 *  latitude:string,
 *  longitude:string
 * }[]}
 */
export var getCities = state => {
  return state && state[BOOKTABLE_REDUX_NAME] && state[BOOKTABLE_REDUX_NAME].cities ? state[BOOKTABLE_REDUX_NAME].cities : [];
};

/**
 *
 * @param {*} state
 * @returns {FavRestaurantData}
 */
export var getFavRestaurant = state => {
  return state && state[BOOKTABLE_REDUX_NAME] && state[BOOKTABLE_REDUX_NAME].favRestaurant ? state[BOOKTABLE_REDUX_NAME].favRestaurant : null;
};
export var getContactId = state => {
  return state && state[BOOKTABLE_REDUX_NAME] && state[BOOKTABLE_REDUX_NAME].contactId ? state[BOOKTABLE_REDUX_NAME].contactId : "";
};
export var getPreviousValues = state => {
  var result = state && state[BOOKTABLE_REDUX_NAME] && state[BOOKTABLE_REDUX_NAME].previousValues ? state[BOOKTABLE_REDUX_NAME].previousValues : "";
  return result;
};
export var getIsBookATableModalOpen = state => state && state[BOOKTABLE_REDUX_NAME] ? !!state[BOOKTABLE_REDUX_NAME].modalOpen : false;

/**
 *
 * @typedef {{
 *  ls_store_id: string,
 *  name: string,
 *  status: status,
 *  frontend_name: string,
 *  latitude,
 *  longitude,
 *  store_preview_image_mobile,
 *  store_preview_image_desktop,
 *  url_key,
 *  address: {
 *      address1: string,
 *      address2,
 *      city: string,
 *      phone_number: string,
 *      region
 *  },
 *  store_hours: {
 *      day,
 *      from,
 *      to,
 *      type
 *  }[]
 * }} StoreFullData
 */

/**
 *
 * @return {StoreFullData | null}
 */
export var getSelectedStore = state => {
  var result = state && state[BOOKTABLE_REDUX_NAME] && state[BOOKTABLE_REDUX_NAME].selectedStore ? state[BOOKTABLE_REDUX_NAME].selectedStore : null;
  return result;
};
export var getModalFeedbackOpen = state => {
  var result = state && state[BOOKTABLE_REDUX_NAME] ? !!state[BOOKTABLE_REDUX_NAME].modalFeedbackOpen : false;
  return result;
};

/**
 *
 * @return {StoreFullData[]}
 */
export var getAllStores = state => {
  var result = state && state[BOOKTABLE_REDUX_NAME] && state[BOOKTABLE_REDUX_NAME].storesFullData ? state[BOOKTABLE_REDUX_NAME].storesFullData : [];
  return result;
};
export var getBookingData = state => state && state[BOOKTABLE_REDUX_NAME] && state[BOOKTABLE_REDUX_NAME].booking ? state[BOOKTABLE_REDUX_NAME].booking : undefined;
export var getIsFavRestaurantSelected = state => state && state[BOOKTABLE_REDUX_NAME] && state[BOOKTABLE_REDUX_NAME].isFavRestaurantSelected ? !!state[BOOKTABLE_REDUX_NAME].isFavRestaurantSelected : false;
export var selectCancelBookingError = state => state && state[BOOKTABLE_REDUX_NAME] && state[BOOKTABLE_REDUX_NAME].cancelBookingError ? !!state[BOOKTABLE_REDUX_NAME].cancelBookingError : null;
export var selectBooking = state => state && state[BOOKTABLE_REDUX_NAME] && state[BOOKTABLE_REDUX_NAME].booking ? state[BOOKTABLE_REDUX_NAME].booking : undefined;
export var selectSearch = state => state && state[BOOKTABLE_REDUX_NAME] && state[BOOKTABLE_REDUX_NAME].search ? state[BOOKTABLE_REDUX_NAME].search : undefined;