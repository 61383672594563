exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".farmBannerTop-module-root{background:#639;height:100vh;display:flex;color:#fff;position:relative;flex-direction:column;justify-content:center;box-shadow:inset 3000px 0 0 0 rgba(26,25,25,.6);background:var(--background-desktop),linear-gradient(233deg, rgba(26, 25, 25, 0) 46.45%, rgba(26, 25, 25, 0.7) 79.97%);background-size:cover;background-position:center;background-repeat:no-repeat}@media screen and (max-width: 640px){.farmBannerTop-module-root{background:var(--background-mobile),linear-gradient(233deg, rgba(26, 25, 25, 0) 46.45%, rgba(26, 25, 25, 0.7) 79.97%);background-size:cover;background-repeat:no-repeat;background-position:center}}.farmBannerTop-module-root .farmBannerTop-module-block_center{align-self:center;max-height:600px;text-align:center}.farmBannerTop-module-root .farmBannerTop-module-block_center .farmBannerTop-module-preTitle{font-size:13px;font-weight:700;text-transform:uppercase}.farmBannerTop-module-root .farmBannerTop-module-block_center .farmBannerTop-module-title{margin-top:24px;font-size:38px;text-transform:uppercase;font-weight:500;font-family:\"Knockout-73FullHeviweight\",sans-serif}.farmBannerTop-module-root .farmBannerTop-module-block_center .farmBannerTop-module-subTitle{margin-top:24px;margin-bottom:48px;font-weight:400;font-size:18px}.farmBannerTop-module-root .farmBannerTop-module-block_center .farmBannerTop-module-video{display:none}", ""]);

// Exports
exports.locals = {
	"root": "farmBannerTop-module-root",
	"block_center": "farmBannerTop-module-block_center",
	"preTitle": "farmBannerTop-module-preTitle",
	"title": "farmBannerTop-module-title",
	"subTitle": "farmBannerTop-module-subTitle",
	"video": "farmBannerTop-module-video"
};