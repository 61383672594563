import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { handleActions } from "redux-actions";
import actions from "@Src_main/overrides/peregrine/store/actions/checkout/actions";
import { getTableData } from "@Src_main/utils/storeConfigs";
export var name = "checkout";
var initialState = {
  availableShippingMethods: [],
  availablePaymentMethods: [],
  billingAddress: null,
  billingAddressError: null,
  isSubmitting: false,
  orderError: null,
  paymentMethodError: null,
  paymentCode: "",
  paymentMethodComponentSelected: null,
  paymentData: null,
  receipt: {
    order: {}
  },
  pickupPoint: null,
  pickupPointAvailability: null,
  pickupTime: null,
  shippingAddress: {},
  shippingAddressError: null,
  shippingMethod: "",
  shippingMethodError: null,
  shippingTitle: "",
  storedShippingData: null,
  checkoutStepCompleted: {
    pickupTime: false,
    payment: false,
    tableData: false,
    shippingMethod: false,
    shippingDataSet: false
  },
  adyenConfig: null,
  adyenCheckout: null,
  adyenState: null,
  adyenAdditionalDetails: null,
  tableData: getTableData(),
  coverSku: null,
  tableStatus: null,
  hospitalityData: null,
  payAtStoreSelected: false
};
var reducerMap = {
  [actions.begin]: (state, _ref) => {
    var {
      payload
    } = _ref;
    return _objectSpread(_objectSpread({}, state), payload);
  },
  [actions.billingAddress.submit]: state => _objectSpread(_objectSpread({}, state), {}, {
    billingAddressError: null,
    isSubmitting: true
  }),
  [actions.billingAddress.accept]: (state, _ref2) => {
    var {
      payload
    } = _ref2;
    // Billing address can either be an object with address props OR
    // an object with a single prop, `sameAsShippingAddress`, so we need
    // to do some special handling to make sure the store reflects that.
    var newState = _objectSpread(_objectSpread({}, state), {}, {
      isSubmitting: false
    });
    if (payload.sameAsShippingAddress) {
      newState.billingAddress = _objectSpread({}, payload);
    } else if (!payload.sameAsShippingAddress) {
      newState.billingAddress = _objectSpread(_objectSpread({}, payload), {}, {
        street: [...payload.street]
      });
    }
    return newState;
  },
  [actions.billingAddress.reject]: (state, _ref3) => {
    var {
      payload
    } = _ref3;
    return _objectSpread(_objectSpread({}, state), {}, {
      billingAddressError: payload,
      isSubmitting: false
    });
  },
  [actions.getShippingMethods.receive]: (state, _ref4) => {
    var {
      payload,
      error
    } = _ref4;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      availableShippingMethods: payload.map(method => _objectSpread(_objectSpread({}, method), {}, {
        code: method.carrier_code,
        title: method.carrier_title
      }))
    });
  },
  [actions.availablePaymentMethods.submit]: (state, _ref5) => {
    var {
      payload,
      error
    } = _ref5;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      availablePaymentMethods: payload
    });
  },
  [actions.shippingAddress.submit]: state => _objectSpread(_objectSpread({}, state), {}, {
    isSubmitting: true,
    shippingAddressError: null
  }),
  [actions.shippingAddress.accept]: (state, _ref6) => {
    var {
      payload
    } = _ref6;
    return _objectSpread(_objectSpread({}, state), {}, {
      isSubmitting: false,
      shippingAddress: _objectSpread(_objectSpread(_objectSpread({}, state.shippingAddress), payload), {}, {
        street: [...payload.street]
      })
    });
  },
  [actions.shippingAddress.reject]: (state, _ref7) => {
    var {
      payload
    } = _ref7;
    return _objectSpread(_objectSpread({}, state), {}, {
      isSubmitting: false,
      shippingAddressError: payload
    });
  },
  [actions.paymentMethod.submit]: (state, _ref8) => {
    var {
      payload
    } = _ref8;
    return _objectSpread(_objectSpread({}, state), {}, {
      isSubmitting: false,
      paymentCode: payload.code,
      paymentData: payload.data
    });
  },
  [actions.paymentMethodComponentSelected.submit]: (state, _ref9) => {
    var {
      payload
    } = _ref9;
    return _objectSpread(_objectSpread({}, state), {}, {
      paymentMethodComponentSelected: payload
    });
  },
  [actions.receipt.setOrder]: (state, _ref10) => {
    var {
      payload
    } = _ref10;
    return _objectSpread(_objectSpread({}, state), {}, {
      receipt: {
        order: payload
      }
    });
  },
  [actions.receipt.reset]: state => _objectSpread(_objectSpread({}, state), {}, {
    receipt: _objectSpread({}, initialState.receipt)
  }),
  [actions.shippingMethod.submit]: state => _objectSpread(_objectSpread({}, state), {}, {
    isSubmitting: true,
    shippingMethodError: null
  }),
  [actions.shippingMethod.accept]: (state, _ref11) => {
    var {
      payload
    } = _ref11;
    return _objectSpread(_objectSpread({}, state), {}, {
      isSubmitting: false,
      shippingMethod: payload.carrier_code,
      shippingTitle: payload.carrier_title
    });
  },
  [actions.shippingMethod.reject]: (state, _ref12) => {
    var {
      payload
    } = _ref12;
    return _objectSpread(_objectSpread({}, state), {}, {
      isSubmitting: false,
      shippingMethodError: payload
    });
  },
  [actions.order.submit]: state => _objectSpread(_objectSpread({}, state), {}, {
    isSubmitting: true,
    orderError: null
  }),
  [actions.order.accept]: state => _objectSpread(_objectSpread({}, state), {}, {
    isSubmitting: false
  }),
  [actions.order.reject]: (state, _ref13) => {
    var {
      payload
    } = _ref13;
    return _objectSpread(_objectSpread({}, state), {}, {
      isSubmitting: false,
      orderError: payload
    });
  },
  [actions.pickupPoint.submit]: (state, _ref14) => {
    var {
      payload
    } = _ref14;
    return _objectSpread(_objectSpread({}, state), {}, {
      isSubmitting: false,
      pickupPoint: payload
    });
  },
  [actions.pickupPointAvailability.submit]: (state, _ref15) => {
    var {
      payload
    } = _ref15;
    return _objectSpread(_objectSpread({}, state), {}, {
      pickupPointAvailability: payload
    });
  },
  [actions.checkoutStepCompleted.submit]: (state, _ref16) => {
    var {
      payload
    } = _ref16;
    return _objectSpread(_objectSpread({}, state), {}, {
      isSubmitting: false,
      checkoutStepCompleted: payload
    });
  },
  [actions.tableData.submit]: (state, _ref17) => {
    var {
      payload
    } = _ref17;
    return _objectSpread(_objectSpread({}, state), {}, {
      isSubmitting: false,
      tableData: payload
    });
  },
  [actions.pickupTime.submit]: (state, _ref18) => {
    var {
      payload
    } = _ref18;
    return _objectSpread(_objectSpread({}, state), {}, {
      isSubmitting: false,
      pickupTime: payload
    });
  },
  [actions.adyenConfig.submit]: (state, _ref19) => {
    var {
      payload
    } = _ref19;
    return _objectSpread(_objectSpread({}, state), {}, {
      adyenConfig: payload
    });
  },
  [actions.adyenCheckout.submit]: (state, _ref20) => {
    var {
      payload
    } = _ref20;
    return _objectSpread(_objectSpread({}, state), {}, {
      adyenCheckout: payload
    });
  },
  [actions.adyenState.submit]: (state, _ref21) => {
    var {
      payload
    } = _ref21;
    return _objectSpread(_objectSpread({}, state), {}, {
      adyenState: payload
    });
  },
  [actions.adyenAdditionalDetails.submit]: (state, _ref22) => {
    var {
      payload
    } = _ref22;
    return _objectSpread(_objectSpread({}, state), {}, {
      adyenAdditionalDetails: payload
    });
  },
  [actions.storedShippingData.submit]: (state, _ref23) => {
    var {
      payload
    } = _ref23;
    return _objectSpread(_objectSpread({}, state), {}, {
      storedShippingData: payload
    });
  },
  [actions.coverSku.submit]: (state, _ref24) => {
    var {
      payload
    } = _ref24;
    return _objectSpread(_objectSpread({}, state), {}, {
      coverSku: payload
    });
  },
  [actions.tableStatus.submit]: (state, _ref25) => {
    var {
      payload
    } = _ref25;
    return _objectSpread(_objectSpread({}, state), {}, {
      tableStatus: payload
    });
  },
  [actions.hospitalityData.submit]: (state, _ref26) => {
    var {
      payload
    } = _ref26;
    return _objectSpread(_objectSpread({}, state), {}, {
      hospitalityData: payload
    });
  },
  [actions.payAtStoreSelected.submit]: (state, _ref27) => {
    var {
      payload
    } = _ref27;
    return _objectSpread(_objectSpread({}, state), {}, {
      payAtStoreSelected: payload
    });
  },
  [actions.reset]: () => initialState
};
export default handleActions(reducerMap, initialState);