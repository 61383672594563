exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".eventInfo-module-eventInfo{max-width:350px;width:100%;min-width:320px;box-shadow:0px 6.25526px 46.9144px rgba(0,0,0,.2);border:1px solid #f1f1f1;border-radius:5px}.eventInfo-module-eventInfo .eventInfo-module-head{background-color:#f9f7f6;color:#1a1919;padding:24px 16px;font-size:13px;min-height:82px}.eventInfo-module-eventInfo .eventInfo-module-head .eventInfo-module-event_name{font-weight:700}.eventInfo-module-eventInfo .eventInfo-module-head .eventInfo-module-choose{background-color:#c15a4c;color:#fffefe;padding:8px 12px;border-radius:5px;font-weight:700}.eventInfo-module-eventInfo .eventInfo-module-bottom>*{width:33.3%;border-right:1px solid rgba(0,0,0,.2);text-align:center;padding:16px;font-size:13px}.eventInfo-module-eventInfo .eventInfo-module-bottom>* .icon-container{margin-bottom:8px}.eventInfo-module-eventInfo .eventInfo-module-bottom>*:last-child{border-right:none}@media(min-width: 1120px){.eventInfo-module-eventInfo{box-shadow:none}.eventInfo-module-eventInfo:hover{box-shadow:0px 6.25526px 46.9144px rgba(0,0,0,.2)}}", ""]);

// Exports
exports.locals = {
	"eventInfo": "eventInfo-module-eventInfo",
	"head": "eventInfo-module-head",
	"event_name": "eventInfo-module-event_name",
	"choose": "eventInfo-module-choose",
	"bottom": "eventInfo-module-bottom"
};