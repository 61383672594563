exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".carousel-module-carousel{margin:60px 0 96px;font-size:13px}.carousel-module-carousel .carousel-module-card{max-width:160px}.carousel-module-carousel img{width:100px;height:100px}.carousel-module-carousel .carousel-module-title{font-size:13px;font-weight:700;margin-top:16px}.carousel-module-carousel .carousel-module-description{margin-top:8px}", ""]);

// Exports
exports.locals = {
	"carousel": "carousel-module-carousel",
	"card": "carousel-module-card",
	"title": "carousel-module-title",
	"description": "carousel-module-description"
};