exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".pageHeader-module-pageHeader .pageHeader-module-contentHeader{height:100%;padding:272px 32px 64px;box-shadow:inset 3000px 0 0 0 rgba(26,25,25,.3)}.pageHeader-module-pageHeader .pageHeader-module-contentHeader .pageHeader-module-title{font-size:32px;margin-bottom:16px}.pageHeader-module-pageHeader .pageHeader-module-contentHeader .pageHeader-module-subtitle{margin-bottom:35px}.pageHeader-module-pageHeader .pageHeader-module-contentHeader .pageHeader-module-description{max-width:510px;margin:0 auto 35px}.pageHeader-module-pageHeader .pageHeader-module-contentHeader button{padding:0 10px}.pageHeader-module-pageHeader .pageHeader-module-contentHeader .icon-container{background-color:#fffefe;width:56px;height:56px;border-radius:50%;margin:190px auto 0}.pageHeader-module-pageHeader .pageHeader-module-contentHeader .icon-container i{font-size:24px}@media(min-width: 1120px){.pageHeader-module-pageHeader .pageHeader-module-contentHeader{padding:340px 85px 85px}.pageHeader-module-pageHeader .pageHeader-module-contentHeader .pageHeader-module-title{font-size:38px}.pageHeader-module-pageHeader .pageHeader-module-contentHeader .pageHeader-module-description{font-size:18px}}", ""]);

// Exports
exports.locals = {
	"pageHeader": "pageHeader-module-pageHeader",
	"contentHeader": "pageHeader-module-contentHeader",
	"title": "pageHeader-module-title",
	"subtitle": "pageHeader-module-subtitle",
	"description": "pageHeader-module-description"
};