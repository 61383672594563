exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".buttonImage-module-buttonImage{min-height:120px;width:100%;max-width:546px;margin:8px auto;padding:24px;position:relative;border-radius:5px;color:#fffefe;background:var(--background-desktop)}@media(max-width: 640px){.buttonImage-module-buttonImage{background:var(--background-mobile)}}.buttonImage-module-buttonImage:hover{color:#fffefe}.buttonImage-module-buttonImage .buttonImage-module-text{margin:0;z-index:1}.buttonImage-module-buttonImage .buttonImage-module-subtitle{z-index:1}.buttonImage-module-buttonImage .buttonImage-module-link{z-index:1}.buttonImage-module-buttonImage .buttonImage-module-link .icon-container{background-color:#fffefe;width:32px;height:32px;border-radius:50%;line-height:32px}.buttonImage-module-buttonImage .buttonImage-module-link .icon-container i{font-size:24px}.buttonImage-module-buttonImage .buttonImage-module-overlay{opacity:.4;border-radius:5px}@media(min-width: 960px){.buttonImage-module-buttonImage .buttonImage-module-text{margin-bottom:auto;font-size:24px}}@media(min-width: 1120px){.buttonImage-module-buttonImage{margin:12px auto;height:200px;max-width:530px}.buttonImage-module-buttonImage .buttonImage-module-link{margin-top:auto}}", ""]);

// Exports
exports.locals = {
	"buttonImage": "buttonImage-module-buttonImage",
	"text": "buttonImage-module-text",
	"subtitle": "buttonImage-module-subtitle",
	"link": "buttonImage-module-link",
	"overlay": "buttonImage-module-overlay"
};