exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".careerReasons-module-root{text-align:center;padding-top:60px;padding-bottom:60px}.careerReasons-module-root .careerReasons-module-title_header{font-weight:500;font-size:18px;font-family:\"Knockout-73FullHeviweight\",sans-serif;margin-bottom:65px;text-transform:uppercase;padding:0 60px}.careerReasons-module-root .careerReasons-module-items{display:none;overflow:auto}.careerReasons-module-root .careerReasons-module-items .careerReasons-module-item{max-width:250px;min-width:160px;margin:0 20px}.careerReasons-module-root .careerReasons-module-items .careerReasons-module-item .careerReasons-module-image{max-height:128px;width:auto;border-radius:50%}.careerReasons-module-root .careerReasons-module-items .careerReasons-module-item .careerReasons-module-title{font-weight:700;font-size:13px;margin-bottom:8px}.careerReasons-module-root .careerReasons-module-items .careerReasons-module-item .careerReasons-module-subtitle{font-weight:400;font-size:13px;margin-top:0}@media(min-width: 1120px){.careerReasons-module-root .careerReasons-module-title_header{padding:0 10px}.careerReasons-module-root .careerReasons-module-items{display:flex;padding:0 10%;justify-content:space-around}.careerReasons-module-root .careerReasons-module-title{text-transform:uppercase}}", ""]);

// Exports
exports.locals = {
	"root": "careerReasons-module-root",
	"title_header": "careerReasons-module-title_header",
	"items": "careerReasons-module-items",
	"item": "careerReasons-module-item",
	"image": "careerReasons-module-image",
	"title": "careerReasons-module-title",
	"subtitle": "careerReasons-module-subtitle"
};