import { createActions } from "redux-actions";
var prefix = "CATALOG";
var actionTypes = ["UPDATE_CATEGORIES"];
var actionMap = {
  SET_CURRENT_PAGE: {
    REQUEST: null,
    RECEIVE: null
  },
  SET_PREV_PAGE_TOTAL: {
    REQUEST: null,
    RECEIVE: null
  },
  SET_PRODUCT_CATALOG: {
    REQUEST: null,
    RECEIVE: null
  },
  SET_SECTION_CATALOG: {
    REQUEST: null,
    RECEIVE: null
  },
  SET_CROSS_SELL_PRODUCTS_MINI_CART: {
    RECEIVE: null
  },
  SET_CROSS_SELL_PRODUCTS_MODAL_FIRST: {
    RECEIVE: null
  },
  SET_CROSS_SELL_PRODUCTS_MODAL_SECOND: {
    RECEIVE: null
  },
  SET_CROSS_SELL_PRODUCTS_BEFORE_CART_MODAL_FIRST: {
    RECEIVE: null
  },
  SET_CROSS_SELL_PRODUCTS_BEFORE_CART_MODAL_SECOND: {
    RECEIVE: null
  },
  SET_MYO_SELECTION: {
    REQUEST: null,
    RECEIVE: null
  },
  SET_FILTERS: {
    REQUEST: null,
    RECEIVE: null
  },
  SET_MEMBER_ATTRIBUTE_FILTERS: {
    RECEIVE: null
  },
  SET_FILTER_PANEL: {
    REQUEST: null,
    RECEIVE: null
  },
  SET_LAST_MODAL_OPEN_SETTER: {
    REQUEST: null,
    RECEIVE: null
  },
  SET_MAIN_MYO_MODAL_OPEN: {
    REQUEST: null,
    RECEIVE: null
  }
};
export default createActions(actionMap, ...actionTypes, {
  prefix
});