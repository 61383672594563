exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".circle-module-circle{overflow:hidden;width:190px;height:190px;border-radius:50%;cursor:pointer}.circle-module-circle .circle-module-content{margin:auto;padding:0 24px}.circle-module-circle .circle-module-content .circle-module-title_circle{font-weight:500;font-size:24px;margin:0 auto 16px}.circle-module-circle .circle-module-content .circle-module-subtitle_circle{text-align:center;font-size:13px;margin-bottom:30px}.circle-module-circle .circle-module-content .icon{font-size:24px}@media(min-width: 640px){.circle-module-circle{width:200px;height:200px}.circle-module-circle .circle-module-content{padding:0 32px}}@media(min-width: 1120px){.circle-module-circle{width:270px;height:270px}.circle-module-circle .circle-module-content .circle-module-title_circle{font-family:\"Knockout-73FullHeviweight\",sans-serif !important;font-weight:500 !important}.circle-module-circle .circle-module-content .circle-module-subtitle_circle{font-size:18px}.circle-module-circle .circle-module-content .icon{font-size:48px}}", ""]);

// Exports
exports.locals = {
	"circle": "circle-module-circle",
	"content": "circle-module-content",
	"title_circle": "circle-module-title_circle",
	"subtitle_circle": "circle-module-subtitle_circle"
};