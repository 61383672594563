exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".card-module-card{border-radius:10px;max-width:356px;cursor:pointer;position:relative}.card-module-card:hover{box-shadow:0px 3.25526px 13.9144px #1a1920}.card-module-card img{object-fit:cover;height:160px}.card-module-card .card-module-category{font-size:13px;width:fit-content;padding:4px 8px;margin:auto;border-radius:5px;font-weight:700;cursor:pointer;position:absolute;top:16px;left:16px;z-index:1}.card-module-card .card-module-body{padding:20px 16px}.card-module-card .card-module-body .card-module-title{color:#fffefe;margin-bottom:8px}.card-module-card .card-module-body .card-module-title.card-module-titleMostPopular{font-style:normal;font-weight:500;font-size:18px;line-height:24px;display:flex;align-items:flex-end;text-transform:uppercase;color:#1a1919}.card-module-card .card-module-body .card-module-text{margin-bottom:32px}.card-module-card .card-module-body .card-module-link{position:absolute;bottom:6px;right:6px}.card-module-card .card-module-body .card-module-link i{font-size:24px}.card-module-card.card-module-cardMostPopular{border:1px solid rgba(26,25,25,.3)}@media(max-width: 959px){.card-module-card.card-module-cardMostPopular{margin:auto}}.card-module-card.card-module-cardMostPopular .card-module-body.card-module-bodyMostPopular{padding-top:16px;padding-left:16px;padding-right:16px;padding-bottom:54px}@media(min-width: 960px){.card-module-card{margin:8px !important}.card-module-card img{height:220px}.card-module-card .card-module-body{padding:64px 16px}.card-module-card.card-module-cardMostPopular{display:flex;flex-direction:column;margin:0 !important;width:100%;min-height:355px;border:1px solid rgba(26,25,25,.3)}.card-module-card.card-module-cardMostPopular .card-module-body.card-module-bodyMostPopular{padding-top:16px;padding-left:16px;padding-right:40px;padding-bottom:54px}.card-module-card.card-module-cardMostPopular img{border-top-left-radius:5px;border-top-right-radius:5px}}", ""]);

// Exports
exports.locals = {
	"card": "card-module-card",
	"category": "card-module-category",
	"body": "card-module-body",
	"title": "card-module-title",
	"titleMostPopular": "card-module-titleMostPopular",
	"text": "card-module-text",
	"link": "card-module-link",
	"cardMostPopular": "card-module-cardMostPopular",
	"bodyMostPopular": "card-module-bodyMostPopular"
};