import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useAppContext } from '@overrides_venia/peregrine/context/app';
import { storesArray } from './storeConfigs';
import { GET_STORES } from "@Src_main/pages/Stores/stores.gql";
export function useStores() {
  var [{
    stores
  }, {
    actions: {
      setStores
    }
  }] = useAppContext();
  var [getStores, {
    data,
    loading
  }] = useLazyQuery(GET_STORES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      var enrichedStores = storesArray.map(x => {
        var storesData = data.stores.find(y => y.ls_store_id === x.id);
        if (storesData !== undefined) {
          return _objectSpread(_objectSpread({}, x), storesData);
        }
      }).filter(x => x !== undefined);
      setStores(enrichedStores);
    }
  });
  useEffect(() => {
    if (stores.length === 0) {
      getStores();
    }
  }, []);
  return {
    stores
  };
}
;
export default useStores;