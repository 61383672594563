var exponeaEmptyValue = " ";
export var checkIfRequestedAreAvailable = (formData, requiredValues) => {
  return !requiredValues.map(key => !!(formDataValesToExponea(formData, key) === exponeaEmptyValue)).some(el => !!el);
};
var formDataValesToExponea = (formData, key) => formData && formData[key] ? typeof formData[key] === "string" ? "".concat(formData[key]).trim() : formData[key] : exponeaEmptyValue;
export var exponeaFormatter = (formData, ElementsValues) => Object.fromEntries(ElementsValues.map(key => [key, formDataValesToExponea(formData, key)]));
export var consent_list_events = {
  privacy_consent_profilation: 365,
  privacy_consent_communication: 3650
};
export var consent_list_book_a_table = {
  privacy_consent_profilation: 365,
  privacy_consent_communication: 3650
};