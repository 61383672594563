exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".contentTitle-module-contentTitle .contentTitle-module-title{font-size:24px}.contentTitle-module-contentTitle .contentTitle-module-pre_description{font-weight:700;font-size:13px;margin:48px 0 16px}.contentTitle-module-contentTitle button{margin-top:40px;max-width:470px}@media(min-width: 1120px){.contentTitle-module-contentTitle .contentTitle-module-description{font-size:18px}.contentTitle-module-contentTitle .contentTitle-module-pre_description{font-size:15px}.contentTitle-module-contentTitle button{margin-top:64px}}", ""]);

// Exports
exports.locals = {
	"contentTitle": "contentTitle-module-contentTitle",
	"title": "contentTitle-module-title",
	"pre_description": "contentTitle-module-pre_description",
	"description": "contentTitle-module-description"
};