import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import queryString from "query-string";
import { getMonth, getYear, parse } from "date-fns";

/**
 *
 * @param {*} filter
 * @param {} history
 */
export var setFiltersToUrl = (filter, location, history) => {
  history.push("/search-blog.html?".concat(queryString.stringify(filter)));
};

/**
 *
 * @param {*} allCategories
 * @returns {{
 *  [categoryId:string]:string
 * }}
 */
export var getCategoriesMap = allCategories => allCategories && allCategories.blogCategories && allCategories.blogCategories.items && Array.isArray(allCategories.blogCategories.items) ? allCategories.blogCategories.items.map(blogCategory => blogCategory ? {
  id: blogCategory.category_id,
  name: blogCategory.name
} : {}).reduce((result, el) => {
  return _objectSpread(_objectSpread({}, result), {}, {
    ["".concat(el.id)]: el.name
  });
}, {}) : {};
export var getCategoriesColor = allCategories => allCategories && allCategories.blogCategories && allCategories.blogCategories.items && Array.isArray(allCategories.blogCategories.items) ? allCategories.blogCategories.items.map(blogCategory => blogCategory ? {
  id: blogCategory.category_id,
  background_hex_label: blogCategory.background_hex_label
} : {}).reduce((result, el) => {
  return _objectSpread(_objectSpread({}, result), {}, {
    ["".concat(el.id)]: el.background_hex_label
  });
}, {}) : {};
export var filtersToFiltersArray = filters => ({
  date: filters && filters.date ? Object.keys(filters.date).filter(month => !!filters.date[month]) : undefined,
  categories: filters && filters.categories ? Object.keys(filters.categories).filter(cat => !!filters.categories[cat]) : undefined
});
export var filterArrayToFilterObject = filterArray => ({
  date: filterArray.date && Array.isArray(filterArray.date) ? filterArray.date.reduce((result, el) => _objectSpread(_objectSpread({}, result), {}, {
    [el]: true
  }), {}) : undefined,
  categories: filterArray.categories && Array.isArray(filterArray.categories) ? filterArray.categories.reduce((result, el) => _objectSpread(_objectSpread({}, result), {}, {
    [el]: true
  }), {}) : undefined
});
export var getAreFilterSelected = filters => !!(filters && filters.categories && Object.keys(filters.categories).find(category => !!filters.categories[category]) || filters.date && Object.keys(filters.date).find(singleDate => !!filters.date[singleDate]));
export var getAreFilterSelectedArray = filters => !!(filters && filters.categories || filters.date);
export var getCategoriesStringIdFromBlogPost = _ref => {
  var {
    categories
  } = _ref;
  return categories && categories.split ? categories.split(", ").map(el => "".concat(el).trim()) : [];
};
export var getMonthIdFromPost = _ref2 => {
  var {
    published_at
  } = _ref2;
  return "".concat(getYear(parse(published_at, "yyyy-MM-dd HH:mm:ss", new Date())), "_").concat(getMonth(parse(published_at, "yyyy-MM-dd HH:mm:ss", new Date())));
};