exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".sliderFull-module-sliderFull{position:relative}.sliderFull-module-sliderFull .sliderFull-module-imageCenter{display:flex;justify-content:center}.sliderFull-module-sliderFull .sliderFull-module-imageCenter img{height:60px;width:135px}.sliderFull-module-sliderFull .sliderFull-module-content{text-align:center;background-size:cover;background-repeat:no-repeat;background-position:center center;height:812px;box-shadow:inset 3000px 0 0 0 rgba(26,25,25,.15)}.sliderFull-module-sliderFull .sliderFull-module-content .sliderFull-module-container{min-height:812px}.sliderFull-module-sliderFull .sliderFull-module-content .sliderFull-module-container .sliderFull-module-video{height:812px !important}.sliderFull-module-sliderFull .sliderFull-module-info{position:absolute;left:0;right:0;bottom:30px;z-index:10;text-align:center}.sliderFull-module-sliderFull .sliderFull-module-info [class^=icon-]{display:block;transform:rotate(180deg)}.sliderFull-module-sliderFull .sliderFull-module-info .sliderFull-module-text{padding:10px 24px 0;font-size:13px}.sliderFull-module-sliderFull .sliderFull-module-title{margin-bottom:16px;margin-top:15px}.sliderFull-module-sliderFull .sliderFull-module-description{margin:15px 0 15px;font-size:24px;font-family:\"Knockout-73FullHeviweight\",sans-serif}.sliderFull-module-sliderFull button{padding:0 32px}@media(min-width: 960px){.sliderFull-module-sliderFull .sliderFull-module-info{bottom:45px}.sliderFull-module-sliderFull .sliderFull-module-info [class^=icon-]{transform:rotate(0deg);font-size:24px}.sliderFull-module-sliderFull .sliderFull-module-info .sliderFull-module-text{padding:43px 24px 24px;font-size:15px}.sliderFull-module-sliderFull .sliderFull-module-title{font-size:24px}}", ""]);

// Exports
exports.locals = {
	"sliderFull": "sliderFull-module-sliderFull",
	"imageCenter": "sliderFull-module-imageCenter",
	"content": "sliderFull-module-content",
	"container": "sliderFull-module-container",
	"video": "sliderFull-module-video",
	"info": "sliderFull-module-info",
	"text": "sliderFull-module-text",
	"title": "sliderFull-module-title",
	"description": "sliderFull-module-description"
};