exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".MostPopular-module-MostPopularBox{display:block;width:100%}.MostPopular-module-MostPopularBox .MostPopular-module-MostPopularBoxTitle{padding:12px}.MostPopular-module-MostPopularBox .MostPopular-module-MostPopularArticles .MostPopular-module-MostPopularArticlesUl{padding:0;margin:0;display:block}.MostPopular-module-MostPopularBox .MostPopular-module-MostPopularArticles .MostPopular-module-MostPopularArticlesUl .MostPopular-module-MostPopularArticlesLi{padding-bottom:12px;display:block}@media(min-width: 960px){.MostPopular-module-MostPopularBox{max-width:1137px;margin:auto}.MostPopular-module-MostPopularBox .MostPopular-module-MostPopularBoxTitle{padding:12px;padding-bottom:27px}.MostPopular-module-MostPopularBox .MostPopular-module-MostPopularArticles .MostPopular-module-MostPopularArticlesUl{padding:0;margin:0;display:flex;flex-wrap:wrap;justify-content:center;align-items:stretch}.MostPopular-module-MostPopularBox .MostPopular-module-MostPopularArticles .MostPopular-module-MostPopularArticlesUl .MostPopular-module-MostPopularArticlesLi{max-width:33.3%;padding:12px}}", ""]);

// Exports
exports.locals = {
	"MostPopularBox": "MostPopular-module-MostPopularBox",
	"MostPopularBoxTitle": "MostPopular-module-MostPopularBoxTitle",
	"MostPopularArticles": "MostPopular-module-MostPopularArticles",
	"MostPopularArticlesUl": "MostPopular-module-MostPopularArticlesUl",
	"MostPopularArticlesLi": "MostPopular-module-MostPopularArticlesLi"
};